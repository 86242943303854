import request from '@/utils/request'


// 查询宠物等级配置列表
export function listPet(query) {
  return request({
    url: '/biz/petGrade/list',
    method: 'get',
    params: query
  })
}

// 查询宠物等级配置分页
export function pagePet(query) {
  return request({
    url: '/biz/petGrade/page',
    method: 'get',
    params: query
  })
}

// 查询宠物等级配置详细
export function getPet(data) {
  return request({
    url: '/biz/petGrade/detail',
    method: 'get',
    params: data
  })
}

// 新增宠物等级配置
export function addPet(data) {
  return request({
    url: '/biz/petGrade/add',
    method: 'post',
    data: data
  })
}

// 修改宠物等级配置
export function updatePet(data) {
  return request({
    url: '/biz/petGrade/edit',
    method: 'post',
    data: data
  })
}

// 删除宠物等级配置
export function delPet(data) {
  return request({
    url: '/biz/petGrade/delete',
    method: 'post',
    data: data
  })
}
