<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="宠物等级名字" prop="gradeName" >
        <a-input v-model="form.gradeName" placeholder="请输入宠物等级名字" />
      </a-form-model-item>
      <a-form-model-item label="宠物阶段名字" prop="stage" >
        <a-input v-model="form.stage" placeholder="请输入宠物阶段名字" />
      </a-form-model-item>
      <a-form-model-item label="最小经验值" prop="minExperienceValue" >
        <a-input v-model="form.minExperienceValue" placeholder="请输入最小经验值" />
      </a-form-model-item>
      <a-form-model-item label="最大经验值" prop="maxExperienceValue" >
        <a-input v-model="form.maxExperienceValue" placeholder="请输入最大经验值" />
      </a-form-model-item>
      <a-form-model-item label="上一等级" prop="upStageId">
        <a-select v-model="form.upStageId" placeholder="请选择上一等级">
          <a-select-option v-for="(item,key) in downStageList" :key="item.gradeName" :value="item.id">
            {{item.gradeName}}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getPet, addPet, updatePet,listPet } from '@/api/biz/petGrade'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      downStageList: [],
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        gradeName: null,

        stage: null,

        minExperienceValue: null,

        maxExperienceValue: null,

        downStageId: null,

        upStageId: null,

        createTime: null,

        remark: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    downStageInit(){
      let param = {
      }
      listPet(param).then(response => {
        this.downStageList = response.data
      })
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        gradeName: null,
        stage: null,
        upStageId: null,
        minExperienceValue: null,
        maxExperienceValue: null,
        downStageId: null,
        createTime: null,
        remark: null
      }
      this.downStageInit();
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getPet({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updatePet(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addPet(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
